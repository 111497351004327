<template>
  <v-layout>
    <v-tooltip
      left
      color="primary"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="view === true"
          color="primary"
          class="mr-1"
          x-small
          v-bind="attrs"
          icon
          outlined
          elevation="5"
          @click="openDetails(item)"
          v-on="on"
        >
          <v-icon dense>
            {{ icons.mdiEyeOutline }}
          </v-icon>
        </v-btn>
      </template>
      <span>Voir</span>
    </v-tooltip>
    <v-tooltip
      bottom
      color="secondary"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="edit === true"
          icon
          outlined
          v-bind="attrs"
          color="info"
          class="mr-1"
          x-small
          fab
          elevation="5"
          @click="openForm(item)"
          v-on="on"
        >
          <v-icon dense>
            {{ icons.mdiPencilOutline }}
          </v-icon>
        </v-btn>
      </template>
      <span>Modifier</span>
    </v-tooltip>
    <v-tooltip
      bottom
      :color="item.deleted_at == null ? 'error' : 'primary'"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="remove === true"
          :color="item.deleted_at == null ? 'error' : 'primary'"
          x-small
          class="mr-1"
          v-bind="attrs"
          outlined
          fab
          icon
          elevation="5"
          @click="openItem(item)"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ item.deleted_at == null ? icons.mdiDeleteOutline : icons.mdiDeleteRestore }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ item.deleted_at == null ? 'Supprimer' : 'Restaurer' }}</span>
    </v-tooltip>
    <v-tooltip
      left
      :color="item.is_locked ? 'primary' : 'error'"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="lock === true"
          class="mr-1"
          :color="item.is_locked ? 'primary' : 'error'"
          x-small
          outlined
          fab
          icon
          v-bind="attrs"
          elevation="5"
          @click="lockItem(item)"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ item.is_locked ? icons.mdiLockOpen : icons.mdiAccountLock }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ item.is_locked ? 'Débloquer' : 'Bloquer' }}</span>
    </v-tooltip>
    <v-tooltip
      bottom
      color="info"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="status"
          color="info"
          x-small
          v-bind="attrs"
          fab
          outlined
          icon
          class="mr-1"
          elevation="5"
          @click="verifyStatus(item)"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ icons.mdiCashCheck }}
          </v-icon>
        </v-btn>
      </template>
      <span>Vérifier le statut</span>
    </v-tooltip>
    <v-tooltip
      left
      :color="item.is_active ? 'primary' : 'error'"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="activate === true"
          class="mr-1"
          :color="item.is_active ? 'primary' : 'error'"
          x-small
          v-bind="attrs"
          icon
          fab
          outlined
          elevation="5"
          @click="activateItem(item)"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ item.is_active ? icons.mdiLockOpen : icons.mdiLock }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ item.is_active ? 'Désactiver' : 'Activer' }}</span>
    </v-tooltip>
    <v-tooltip
      right
      color="warning"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="apikey === true"
          class="mr-1"
          color="warning"
          x-small
          v-bind="attrs"
          icon
          fab
          outlined
          elevation="5"
          @click="openKeyItem(item)"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ icons.mdiKeyVariant }}
          </v-icon>
        </v-btn>

      </template>
      <span>Clé</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
import {
  mdiAccountLock,
  mdiDeleteOutline, mdiDeleteRestore,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus, mdiRefresh,
  mdiSquareEditOutline, mdiCashCheck, mdiLock, mdiKeyVariant
} from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  name: 'ButtonsGroup',

  emits: ['edit', 'remove', 'view', 'lock', 'verifystatus', 'activate', 'apikey'],

  props: {
    item: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    remove: {
      type: Boolean,
      default: true,
    },
    view: {
      type: Boolean,
      default: true,
    },
    lock: {
      type: Boolean,
      default: true,
    },
    status: {
      type: Boolean,
      default: true,
    },
    activate: {
      type: Boolean,
      default: false,
    },
    apikey: {
      type: Boolean,
      default: false,
    },
  },

  setup({ emit }) {
    const instance = getCurrentInstance()
    const openDetails = item => {
      instance.emit('view', item)
    }

    const openForm = item => {
      instance.emit('edit', item)
    }

    const openItem = item => {
      instance.emit('remove', item)
    }

    const lockItem = item => {
      instance.emit('lock', item)
    }

    const verifyStatus = item => {
      instance.emit('verifystatus', item)
    }

    const activateItem = item => {
      instance.emit('activate', item)
    }

    const openKeyItem = item => {
      instance.emit('apikey', item)
    }

    return {
      openKeyItem,
      activateItem,
      verifyStatus,
      lockItem,
      openItem,
      openForm,
      openDetails,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiDeleteRestore,
        mdiPlus,
        mdiCashCheck,
        mdiLock,
        mdiKeyVariant,
      },
    }
  },
}
</script>

<style scoped>

</style>
